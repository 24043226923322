import React, { useEffect, useState } from "react";
import { Formik, FastField } from "formik";
// Components
import Button from "components/Button";
import { Input, Textarea, InputMasked } from "components/Form";
import FormWrapper from "components/FormWrapper";
// Helpers
import { PHONE_MASK } from "helpers/form-helpers";
// Styles
import { Row } from "components/Grid";
import { ColMedium, ColLarge } from "components/FormWrapper/styles";
import { sendContact } from "services/contact";

function FormContact() {
  const [canShowMessage, setMessage] = useState(false);

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        msg: "",
      }}
      onSubmit={async (values, actions) => {
        setMessage(true);

        setTimeout(() => {
          setMessage(false);
        }, 5000);

        await sendContact(values);

        // Reseta os valores do formulário
        return actions.resetForm();
      }}
      validate={(values) => {
        let errors = {};

        if (!values.name) {
          errors.name = "Campo obrigatório";
        }

        if (!values.phone) {
          errors.phone = "Campo obrigatório";
        }

        if (!values.email) {
          errors.email = "Campo obrigatório";
        }

        if (!values.msg) {
          errors.msg = "Campo obrigatório";
        }

        return errors;
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <FormWrapper canShowMessage={canShowMessage} onSubmit={handleSubmit}>
          <Row spacing="30px">
            <ColMedium>
              <FastField
                label="Nome"
                name="name"
                component={Input}
                placeholder="Digite seu nome"
              />
            </ColMedium>
            <ColMedium>
              <FastField
                mask={PHONE_MASK}
                label="Telefone"
                name="phone"
                component={InputMasked}
                placeholder="Digite seu telefone"
              />
            </ColMedium>
          </Row>
          <Row>
            <ColLarge>
              <FastField
                type="email"
                label="E-mail"
                name="email"
                component={Input}
                placeholder="Digite seu e-mail"
              />
            </ColLarge>
          </Row>
          <Row>
            <ColLarge>
              <FastField
                label="Mensagem"
                name="msg"
                component={Textarea}
                placeholder="Digite sua mensagem"
              />
            </ColLarge>
          </Row>
          <Row>
            <ColLarge style={{ textAlign: "right" }}>
              <Button
                disabled={isSubmitting}
                as="button"
                type="submit"
                kind="solid"
                color="secondary"
              >
                Enviar Mensagem
              </Button>
            </ColLarge>
          </Row>
        </FormWrapper>
      )}
    </Formik>
  );
}

FormContact.propTypes = {};
FormContact.defaultProps = {};

export default FormContact;
