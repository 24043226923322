import React from "react";
// Components
import Seo from "components/Seo";
import Layout from "components/Layout";
import MainTitle from "components/MainTitle";
import FormContact from "components/FormContact";
// Sections - Contato
import SectionAddress from "sections/contato/SectionAddress";

export default function Suporte(props) {
  return (
    <Layout {...props}>
      <Seo
        title="Fale Conosco - Como entrar em contato"
        description={`Deseja esclarecer dúvidas ou enviar sugestões? Nossa equipe está pronta para lhe atender.`}
      />
      <MainTitle
        titleTag="h1"
        title="Fale conosco"
        text={
          <>
            Deseja esclarecer dúvidas ou enviar sugestões?
            <br />
            Nossa equipe está pronta para lhe atender.
          </>
        }
      />
      <FormContact />
      <SectionAddress />
    </Layout>
  );
}
